.PhoneInputInput {
  border-radius: 8px;
  background-color: #F7F7F7;
  border: none;
  padding: 13px 20px;
  width: 100%;
  height: 50px;
  outline: none;
  font-size: 15px;
  color: #1B1A57;
  font-weight: 400;
}


.PhoneInput--focus > .PhoneInputInput{
  background-color: rgba(194, 193, 218, 0.2);
}